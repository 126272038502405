import React from 'react';
import '../style/Journey.css';
import journeyBg from '../../asset/JourneyBg.png'; // Import the background image
import journey1 from '../../asset/Journey1.jpg';
import journey2 from '../../asset/Journey2.jpg';
import journey3 from '../../asset/Journey3.jpg';
import journey4 from '../../asset/Journey4.jpg';
import journey5 from '../../asset/Journey5.jpeg';
import journey6 from '../../asset/Journey6.webp';
import journey7 from '../../asset/Journey7.jpg';

const Journey = () => {
    return (
        <div className="journey-section" style={{ backgroundImage: `url(${journeyBg})` }}>
            <h1 className="journey-heading">IMTS Journey</h1>
            <div className="journey-content">
                {/* Section 1 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Foundation and Vision</h2>
                        <p>Founded in 2005, IMTS Institute began with a vision to transform the educational landscape by offering accessible, flexible, and quality education. Over the past 17 years, IMTS has grown into a distinguished institution, guiding countless students towards their academic and professional goals through a commitment to excellence and innovation.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey1} alt="Foundation and Vision" />
                    </div>
                </div>

                {/* Section 2 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Inception and Mission</h2>
                        <p>The inception of IMTS Institute was driven by a belief in the transformative power of education. Recognizing the need for a more adaptable and student-centric approach, the founders aimed to bridge the gap between traditional education and the needs of modern learners. From the outset, IMTS sought to provide quality education across various fields, ensuring that students could pursue their dreams regardless of their circumstances.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey2} alt="Inception and Mission" />
                    </div>
                </div>

                {/* Section 3 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Early Years: Building a Strong Foundation</h2>
                        <p>Early Years focused on creating a solid base. IMTS developed a comprehensive and adaptable curriculum, offering a range of management and technical courses that met industry standards. The institute also prioritized hiring experienced educators passionate about student development. Their expertise and dedication played a crucial role in establishing IMTS as a credible educational institution.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey3} alt="Early Years" />
                    </div>
                </div>

                {/* Section 4 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Expansion and Growth</h2>
                        <p>Expansion and Growth saw IMTS Institute broadening its course offerings in response to the evolving educational landscape. The institute recognized the increasing importance of distance and online education, particularly for students unable to attend regular classes due to various constraints. IMTS embraced technology to deliver high-quality distance education programs, widening its reach and reinforcing its commitment to flexible learning solutions. Digital platforms allowed IMTS to offer an interactive learning experience that rivaled traditional classroom settings.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey4} alt="Expansion and Growth" />
                    </div>
                </div>

                {/* Section 5 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Commitment to Excellence</h2>
                        <p>Commitment to Excellence has been a key factor in IMTS’s success. The institute maintains high standards in all aspects of education, from curriculum design to student support services. Rigorous quality control measures ensure that every course meets the highest academic standards. IMTS regularly updates its curriculum to keep pace with industry trends and technological advancements, ensuring that students receive relevant and forward-looking education, equipping them with the skills and knowledge needed to thrive in a rapidly changing world.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey5} alt="Commitment to Excellence" />
                    </div>
                </div>

                {/* Section 6 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Career Counseling</h2>
                        <p>Career Counseling is a hallmark of IMTS Institute. With a team of over 350 expert career counselors, the institute offers personalized guidance on the scope and prospects of various subjects. These counselors assess students' strengths, interests, and aspirations, offering tailored advice that aligns with their abilities. This holistic approach to career counseling alleviates stress and empowers students to pursue their goals with confidence. By helping students identify the best-fit subjects and career paths, IMTS ensures they are well-prepared for future success.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey6} alt="Career Counseling" />
                    </div>
                </div>

                {/* Section 7 */}
                <div className="journey-item">
                    <div className="journey-text">
                        <h2>Embracing Technology</h2>
                        <p>Embracing Technology has been pivotal for IMTS. Recognizing the potential of online education, the institute invested in advanced digital infrastructure to deliver a seamless and engaging learning experience. This transition enabled IMTS to reach a global audience, breaking down geographical barriers and making education more accessible. The online education platform at IMTS is user-friendly and interactive, incorporating multimedia resources, virtual classrooms, and real-time feedback mechanisms. This approach enhances the learning experience and fosters a sense of community among students.</p>
                    </div>
                    <div className="journey-image">
                        <img src={journey7} alt="Embracing Technology" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Journey;
