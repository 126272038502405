import React from 'react';
import '../style/Review.css';
import profile1 from '../../asset/profile1.jpg'; // Sample profile image


const reviews = [
    {
        text: `"IMTS Institute is a game-changer in education. Their innovative approach to learning aligns perfectly with the future of technology. The courses are well-structured, and the faculty is outstanding. Highly recommend it for anyone looking to excel in today's fast-paced world."`,
        profilePic: profile1,
        name: 'John Doe',
        stars: 5,
        designation: 'Software Engineer'
    },
    {
        text: `"IMTS Institute stands out for its commitment to quality education. The diverse range of courses ensures that students are well-prepared for the challenges of the modern workplace. IMTS is setting new benchmarks in educational excellence."`,
        profilePic: profile1,
        name: 'Jane Smith',
        stars: 5,
        designation: 'Data Analyst'
    },
    {
        text: `"IMTS Institute offers a transformative educational experience. The curriculum is comprehensive, and the faculty is deeply invested in student success. IMTS empowers learners to achieve their full potential, making it a top choice for aspiring professionals."`,
        profilePic: profile1,
        name: 'Michael Brown',
        stars: 5,
        designation: 'Project Manager'
    },
    {
        text: `"IMTS Institute provides an unparalleled learning environment. Their focus on practical, real-world skills is exactly what today's professionals need. The courses are rigorous yet rewarding, making IMTS a leader in educational innovation."`,
        profilePic: profile1,
        name: 'Emily Davis',
        stars: 5,
        designation: 'UX Designer'
    }
];

const Review = () => {
    return (
        <div className="review-section">
            <div className="left-side">
                <h1>Students are Loving IMTS</h1>
                <div className="stars">
                    {'★★★★★'} {/* This is a simplified star representation */}
                </div>
            </div>

            <div className="right-side">
                <div className="review-grid">
                    {reviews.map((review, index) => (
                        <div key={index} className="review-box">
                            <p>{review.text}</p>
                            <div className="review-profile">
                                <img src={review.profilePic} alt={`${review.name} profile`} />
                                <div className="review-details">
                                    <strong>{review.name}</strong>
                                    <div className="stars">{'★★★★★'}</div>
                                    <span>{review.designation}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Review;
