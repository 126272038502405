import React from 'react';
import '../style/Yt.css';

const Yt = () => {
    // Video URLs you provided
    const videoIds = [
        'vuIhUWtG_Uw', // First video
        'CuLIVbkrJ3A', // Second video
        'Fj6rsKpDLkg', // Third video
        '3FtTudYtrWQ', // Fourth video
        'jJr9XFKCU9I', // Fifth video
        'NvkjuVQPY_g', // Sixth video
    ];

    return (
        <div className="yt-section">
            <h1>Our Counselling Videos</h1>
            <div className="yt-videos">
                {videoIds.map((videoId, index) => (
                    <div key={index} className="yt-video">
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            title={`YouTube Video ${index + 1}`}
                        ></iframe>
                    </div>
                ))}
            </div>
            <a href="https://www.youtube.com/playlist?list=PLcRUb1Mgm4w6bslBE_s83ziFmqZY_C9ze" target="_blank" rel="noopener noreferrer" className="see-more-btn">
                See More
            </a>
        </div>
    );
};

export default Yt;
