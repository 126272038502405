import React from 'react';
import '../style/EducationType.css';
import Ed1 from '../../asset/Ed1.png'; 
import Ed2 from '../../asset/Ed2.png'; 
import Ed3 from '../../asset/Ed3.png'; 

const EducationType = () => {
    return (
        <div className="education-type-section">
            <h1>Education Type</h1>
            <div className="education-type-content">
                <div className="education-item">
                    <div className="text">
                        <h2>Distance Education</h2>
                        <p>
                            Distance education is a mode of learning where students and instructors are geographically separated. It typically involves correspondence courses, online resources, and periodic in-person sessions. This flexible system allows learners to study at their own pace and convenience, making it ideal for those balancing other commitments.
                        </p>
                    </div>
                    <div className="image">
                        <img src={Ed1} alt="Distance Education" />
                    </div>
                </div>
                <div className="education-item reverse">
                    <div className="image">
                        <img src={Ed2} alt="Regular Education" />
                    </div>
                    <div className="text">
                        <h2>Regular Education</h2>
                        <p>
                            Regular education refers to traditional, in-person learning conducted in a classroom setting. It involves direct interaction between students and teachers, structured schedules, and a fixed curriculum. This method fosters a more interactive and disciplined learning environment, promoting face-to-face engagement and collaborative activities.
                        </p>
                    </div>
                </div>
                <div className="education-item">
                    <div className="text">
                        <h2>Online Education</h2>
                        <p>
                            Online education is a form of distance learning that is conducted entirely over the internet. It utilizes digital platforms, virtual classrooms, and multimedia resources to deliver course content. This method offers flexibility and accessibility, allowing students to learn from anywhere with an internet connection and often at their own pace.
                        </p>
                    </div>
                    <div className="image">
                        <img src={Ed3} alt="Online Education" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EducationType;
