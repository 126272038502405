import React from 'react';
import '../style/Faq.css';

const Faq = () => {
    return (
        <div className="faq-section">
            <h1 className="faq-heading">Frequently Asked Questions</h1>
            <div className="faq-container">
                
                <div className="faq-item">
                    <h3>What programs and courses does IMTS Institute offer?</h3>
                    <p>IMTS Institute offers a wide range of undergraduate, postgraduate, and diploma programs across various disciplines.</p>
                </div>

                <div className="faq-item">
                    <h3>How does IMTS Institute support distance and online education?</h3>
                    <p>IMTS provides flexible learning options through online platforms and resources, ensuring that students can learn from anywhere.</p>
                </div>

                <div className="faq-item">
                    <h3>What are the career counseling services provided by IMTS Institute?</h3>
                    <p>IMTS offers personalized career counseling to help students choose the right courses and career paths based on their goals.</p>
                </div>

                <div className="faq-item">
                    <h3>How does IMTS Institute ensure the quality of its education?</h3>
                    <p>IMTS ensures high-quality education through expert faculty, up-to-date curriculum, and continuous evaluation processes.</p>
                </div>

                <div className="faq-item">
                    <h3>What are the admission requirements and process for IMTS Institute?</h3>
                    <p>Students need to fill out an online application form, submit required documents, and undergo a counseling process to complete admission.</p>
                </div>

                <div className="faq-item">
                    <h3>What kind of student support services does IMTS Institute offer?</h3>
                    <p>IMTS offers support through academic advisors, career counseling, and online assistance for students throughout their course journey.</p>
                </div>

                <div className="faq-item">
                    <h3>How does IMTS Institute stay current with industry trends and advancements?</h3>
                    <p>IMTS regularly updates its curriculum and collaborates with industry experts to align its courses with the latest industry trends.</p>
                </div>

            </div>
        </div>
    );
};

export default Faq;
