import './App.css';
import Home from './Component/main/Home.js';
import Course from './Component/main/Course.js';
import GuideVideo from './Component/main/GuideVideo.js';
import Review from './Component/main/Review.js';
import EducationType from './Component/main/EducationType.js';
import Choose from './Component/main/Choose.js';
import Yt from './Component/main/Yt.js';
import Content from './Component/main/Content.js';
import Journey from './Component/main/Journey.js';
import AdmissionSteps from './Component/main/AdmissionSteps.js';
import Faq from './Component/main/Faq.js';
import Footer from './Component/main/Footer.js';
import PhotoSlider from './Component/main/Slider.jsx';

function App() {
  return (
  <>
    <Home/>
    <Course/>
    <GuideVideo/>
    <Review/>
    <EducationType/>
    <Choose/>
    <Yt/>
    <Content/>
    <Journey/>
    <AdmissionSteps/>
    <PhotoSlider/>
    <Faq/>
    <Footer/>
  </>
  );
}

export default App;