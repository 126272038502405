import React, { useState } from 'react'; // Import useState to manage state
import '../style/Course.css';
import whatsappIcon from '../../asset/Whatsapp.png';
import callIcon from '../../asset/call.png';
import applyIcon from '../../asset/Apply.png';

const Course = () => {
  const courses = {
    graduation: [
      {
        name: 'B.Tech',
        eligibility: '10+2 with Science Stream',
        duration: '4 Years',
        FeeRange: '$20,000 - $40,000',
        courseList: [
          'Mechanical Engineering', 'Civil Engineering', 'Computer Science Engineering', 'Electrical & Communication Engineering', 'Electrical & Electronics Engineering', 'Automobile Engineering', 'Chemical Engineering', 'Instrumentation and Control', 'Information Technology', 'Agriculture'
        ],
        specializationCourses: [
          'Robotics', 'Data Science', 'AI and Machine Learning', 'Cybersecurity', 'IoT', 'Embedded Systems'
        ]
      },
      {
        name: 'B.Sc',
        eligibility: '10+2 with Science Stream',
        duration: '3 Years',
        FeeRange: '$10,000 - $20,000',
        courseList: ['Physics', 'Chemistry', 'Mathematics', 'Biology'],
        specializationCourses: ['Astrophysics', 'Organic Chemistry', 'Genetics']
      },
      {
        name: 'B.Com',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'BBA',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'BBA2',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'BCA',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'B.A',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'B.Design',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'BJMC',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
      {
        name: 'BJMC2',
        eligibility: '10+2 with Commerce Stream',
        duration: '3 Years',
        FeeRange: '$8,000 - $15,000',
        courseList: ['Accounting', 'Finance', 'Economics'],
        specializationCourses: ['Corporate Accounting', 'Investment Banking', 'Taxation']
      },
    ],
    postGraduation: [
      {
        name: 'M.Tech',
        eligibility: 'B.Tech or equivalent',
        duration: '2 Years',
        FeeRange: '$15,000 - $30,000',
        courseList: ['Civil Engineering', 'Chemical Engineering', 'Electrical Engineering', 'Mechanical Engineering', 'Computer Science Engineering'],
        specializationCourses: ['Structural Engineering', 'Renewable Energy', 'Advanced Robotics']
      },
      {
        name: 'MBA',
        eligibility: 'Bachelor’s Degree',
        duration: '2 Years',
        FeeRange: '$25,000 - $45,000',
        courseList: ['Finance', 'Marketing', 'Operations'],
        specializationCourses: ['Financial Analysis', 'Digital Marketing', 'Supply Chain Management']
      },
      {
        name: 'M.Com',
        eligibility: 'Bachelor’s Degree',
        duration: '2 Years',
        FeeRange: '$25,000 - $45,000',
        courseList: ['Finance', 'Marketing', 'Operations'],
        specializationCourses: ['Financial Analysis', 'Digital Marketing', 'Supply Chain Management']
      },
      {
        name: 'MCA',
        eligibility: 'Bachelor’s Degree',
        duration: '2 Years',
        FeeRange: '$25,000 - $45,000',
        courseList: ['Finance', 'Marketing', 'Operations'],
        specializationCourses: ['Financial Analysis', 'Digital Marketing', 'Supply Chain Management']
      },
      {
        name: 'MHM',
        eligibility: 'Bachelor’s Degree',
        duration: '2 Years',
        FeeRange: '$25,000 - $45,000',
        courseList: ['Finance', 'Marketing', 'Operations'],
        specializationCourses: ['Financial Analysis', 'Digital Marketing', 'Supply Chain Management']
      },
      {
        name: 'M.Com',
        eligibility: 'Bachelor’s Degree',
        duration: '2 Years',
        FeeRange: '$25,000 - $45,000',
        courseList: ['Finance', 'Marketing', 'Operations'],
        specializationCourses: ['Financial Analysis', 'Digital Marketing', 'Supply Chain Management']
      },
      {
        name: 'MSC',
        eligibility: 'Bachelor’s Degree',
        duration: '2 Years',
        FeeRange: '$25,000 - $45,000',
        courseList: ['Finance', 'Marketing', 'Operations'],
        specializationCourses: ['Financial Analysis', 'Digital Marketing', 'Supply Chain Management']
      },
    ],
    diploma: [
      {
        name: 'Diploma (1 Year)',
        eligibility: '10th Pass',
        duration: '3 Years',
        FeeRange: '$5,000 - $10,000',
        courseList: ['Marketing Management', 'Customer Relationship', 'Information Technology'],
        specializationCourses: ['Advanced Customer Relationship', 'Brand Management', 'IT Infrastructure']
      },
      {
        name: 'Diploma (2 Year)',
        eligibility: '10th Pass',
        duration: '3 Years',
        FeeRange: '$5,000 - $10,000',
        courseList: ['Marketing Management', 'Customer Relationship', 'Information Technology'],
        specializationCourses: ['Advanced Customer Relationship', 'Brand Management', 'IT Infrastructure']
      },
      {
        name: 'Diploma (3 Year)',
        eligibility: '10th Pass',
        duration: '3 Years',
        FeeRange: '$5,000 - $10,000',
        courseList: ['Marketing Management', 'Customer Relationship', 'Information Technology'],
        specializationCourses: ['Advanced Customer Relationship', 'Brand Management', 'IT Infrastructure']
      }
    ],
    others: [
      {
        name: 'Certifications',
        eligibility: 'Depends on the course',
        duration: '3-6 Months',
        FeeRange: '$500 - $1,500',
        courseList: ['Digital Marketing', 'Data Science', 'Cybersecurity'],
        specializationCourses: ['Advanced Cybersecurity', 'Data Science with Python', 'SEO and SEM']
      },
      {
        name: 'B.Voc',
        eligibility: 'Depends on the course',
        duration: '3-6 Months',
        FeeRange: '$500 - $1,500',
        courseList: ['Digital Marketing', 'Data Science', 'Cybersecurity'],
        specializationCourses: ['Advanced Cybersecurity', 'Data Science with Python', 'SEO and SEM']
      },
      {
        name: 'M.Voc',
        eligibility: 'Depends on the course',
        duration: '3-6 Months',
        FeeRange: '$500 - $1,500',
        courseList: ['Digital Marketing', 'Data Science', 'Cybersecurity'],
        specializationCourses: ['Advanced Cybersecurity', 'Data Science with Python', 'SEO and SEM']
      }
    ]
  };

  // State to track the selected course
  const [selectedCourse, setSelectedCourse] = useState(null);

  const toggleCourseDetails = (course) => {
    // Toggle course details display
    setSelectedCourse(selectedCourse === course ? null : course);
  };

  return (
    <div className="course-container">
      <h1>Courses We Offer</h1>

      {/* Graduation Section */}
      <div className="course-section">
        <h2>Graduation</h2>
        <div className="course-cards">
          {courses.graduation.map((course, index) => (
            <div className="course-card" key={index}>
              <h3 onClick={() => toggleCourseDetails(course.name)} style={{ cursor: 'pointer' }}>{course.name}</h3>
              {/* Only show details and icons if this course is selected */}
              {selectedCourse === course.name && (
                <>
                  <p><strong>Eligibility:</strong> {course.eligibility}</p>
                  <p><strong>Duration:</strong> {course.duration}</p>
                  <p><strong>Fee Range:</strong> {course.FeeRange}</p>

                  {/* Available Courses */}
                  <div className="course-list">
                    <h4>Available Courses:</h4>
                    <ul className={course.courseList.length > 11 ? "two-column-list" : ""}>
                      {course.courseList.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Specialization Courses */}
                  <div className="course-list">
                    <h4>Specialization Courses:</h4>
                    <ul className={course.specializationCourses.length > 5 ? "two-column-list" : ""}>
                      {course.specializationCourses.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Icons Section */}
                  <div className="icons">
                    {/* WhatsApp link with country code 91 */}
                    <a href="https://api.whatsapp.com/send?phone=919210989898" target="_blank" rel="noopener noreferrer">
                      <img src={whatsappIcon} alt="WhatsApp" className="icon" />
                    </a>

                    {/* Call link with country code 91 */}
                    <a href="tel:+919210989898">
                      <img src={callIcon} alt="Call Now" className="icon" />
                    </a>

                    <img src={applyIcon} alt="Apply Now" className="icon" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Post Graduation Section */}
      <div className="course-section">
        <h2>Post Graduation</h2>
        <div className="course-cards">
          {courses.postGraduation.map((course, index) => (
            <div className="course-card" key={index}>
              <h3 onClick={() => toggleCourseDetails(course.name)} style={{ cursor: 'pointer' }}>{course.name}</h3>
              {/* Only show details and icons if this course is selected */}
              {selectedCourse === course.name && (
                <>
                  <p><strong>Eligibility:</strong> {course.eligibility}</p>
                  <p><strong>Duration:</strong> {course.duration}</p>
                  <p><strong>Fee Range:</strong> {course.FeeRange}</p>

                  {/* Available Courses */}
                  <div className="course-list">
                    <h4>Available Courses:</h4>
                    <ul className={course.courseList.length > 11 ? "two-column-list" : ""}>
                      {course.courseList.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Specialization Courses */}
                  <div className="course-list">
                    <h4>Specialization Courses:</h4>
                    <ul className={course.specializationCourses.length > 5 ? "two-column-list" : ""}>
                      {course.specializationCourses.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Icons Section */}
                  <div className="icons">
                    {/* WhatsApp link with country code 91 */}
                    <a href="https://api.whatsapp.com/send?phone=918700119609" target="_blank" rel="noopener noreferrer">
                      <img src={whatsappIcon} alt="WhatsApp" className="icon" />
                    </a>

                    {/* Call link with country code 91 */}
                    <a href="tel:+917357257307">
                      <img src={callIcon} alt="Call Now" className="icon" />
                    </a>

                    <img src={applyIcon} alt="Apply Now" className="icon" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Diploma Section */}
      <div className="course-section">
        <h2>Diploma</h2>
        <div className="course-cards">
          {courses.diploma.map((course, index) => (
            <div className="course-card" key={index}>
              <h3 onClick={() => toggleCourseDetails(course.name)} style={{ cursor: 'pointer' }}>{course.name}</h3>
              {/* Only show details and icons if this course is selected */}
              {selectedCourse === course.name && (
                <>
                  <p><strong>Eligibility:</strong> {course.eligibility}</p>
                  <p><strong>Duration:</strong> {course.duration}</p>
                  <p><strong>Fee Range:</strong> {course.FeeRange}</p>

                  {/* Available Courses */}
                  <div className="course-list">
                    <h4>Available Courses:</h4>
                    <ul className={course.courseList.length > 11 ? "two-column-list" : ""}>
                      {course.courseList.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Specialization Courses */}
                  <div className="course-list">
                    <h4>Specialization Courses:</h4>
                    <ul className={course.specializationCourses.length > 5 ? "two-column-list" : ""}>
                      {course.specializationCourses.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Icons Section */}
                  <div className="icons">
                    {/* WhatsApp link with country code 91 */}
                    <a href="https://api.whatsapp.com/send?phone=918700119609" target="_blank" rel="noopener noreferrer">
                      <img src={whatsappIcon} alt="WhatsApp" className="icon" />
                    </a>

                    {/* Call link with country code 91 */}
                    <a href="tel:+917357257307">
                      <img src={callIcon} alt="Call Now" className="icon" />
                    </a>

                    <img src={applyIcon} alt="Apply Now" className="icon" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Others Section */}
      <div className="course-section">
        <h2>Others</h2>
        <div className="course-cards">
          {courses.others.map((course, index) => (
            <div className="course-card" key={index}>
              <h3 onClick={() => toggleCourseDetails(course.name)} style={{ cursor: 'pointer' }}>{course.name}</h3>
              {/* Only show details and icons if this course is selected */}
              {selectedCourse === course.name && (
                <>
                  <p><strong>Eligibility:</strong> {course.eligibility}</p>
                  <p><strong>Duration:</strong> {course.duration}</p>
                  <p><strong>Fee Range:</strong> {course.FeeRange}</p>

                  {/* Available Courses */}
                  <div className="course-list">
                    <h4>Available Courses:</h4>
                    <ul className={course.courseList.length > 11 ? "two-column-list" : ""}>
                      {course.courseList.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Specialization Courses */}
                  <div className="course-list">
                    <h4>Specialization Courses:</h4>
                    <ul className={course.specializationCourses.length > 5 ? "two-column-list" : ""}>
                      {course.specializationCourses.map((c, i) => (
                        <li key={i}>{c}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Icons Section */}
                  <div className="icons">
                    {/* WhatsApp link with country code 91 */}
                    <a href="https://api.whatsapp.com/send?phone=918700119609" target="_blank" rel="noopener noreferrer">
                      <img src={whatsappIcon} alt="WhatsApp" className="icon" />
                    </a>

                    {/* Call link with country code 91 */}
                    <a href="tel:+917357257307">
                      <img src={callIcon} alt="Call Now" className="icon" />
                    </a>

                    <img src={applyIcon} alt="Apply Now" className="icon" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Course;
