import React from 'react';
import '../style/Choose.css';
import Choose1 from '../../asset/Choose1.png';  
import Choose2 from '../../asset/Choose2.png';  
import Choose3 from '../../asset/Choose3.png';  
import Choose4 from '../../asset/Choose4.png';  
import Choose5 from '../../asset/Choose5.png';  



const reasons = [
    {
        title: 'Innovative Learning ',
        description: 'IMTS Institute leverages cutting-edge technology to provide a modern and engaging learning experience. Our use of virtual classrooms, interactive modules, and AI-driven personalized learning paths ensures that each student can learn at their own pace and in their own style.',
        image: Choose3
    },
    {
        title: 'Expert Faculty',
        description: 'Our faculty comprises industry experts and seasoned educators who bring real-world experience and academic rigor to the classroom. This blend of practical knowledge and theoretical foundation prepares students for both immediate career opportunities and long-term professional growth.',
        image: Choose2
    },
    {
        title: 'Flexible Learning Options',
        description: 'Understanding the diverse needs of our students, IMTS provides flexible learning options, including full-time, part-time, and online courses. This flexibility ensures that students can balance their studies with personal and professional commitments.',
        image: Choose1
    },
    {
        title: 'Affordable Education',
        description: 'We believe that quality education should be accessible to all. IMTS offers competitive tuition rates and various scholarships and financial aid options to support students from different financial backgrounds.',
        image: Choose4
    },
    {
        title: 'Proven Placement Record',
        description: 'IMTS Institute proudly boasts a remarkable placement track record, having successfully placed over 24,000 students in top companies across various industries, helping them achieve their career goals and professional aspirations..',
        image: Choose1
    },
    {
        title: 'Global Recognition',
        description: 'IMTS has established itself as a reputable institution with global recognition. Our partnerships with international universities and organizations enhance the educational experience and provide students with opportunities for global exposure.',
        image: Choose5
    }
];

const Choose = () => {
    return (
        <div className="choose-section">
            <h1>Why You Choose IMTS</h1>
            <div className="choose-content">
                {reasons.map((reason, index) => (
                    <div key={index} className={`choose-item ${index % 2 === 1 ? 'reverse' : ''}`}>
                        <div className="text">
                            <h2>{reason.title}</h2>
                            <p>{reason.description}</p>
                        </div>
                        <div className="image">
                            <img src={reason.image} alt={reason.title} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Choose;
