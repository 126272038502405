import React from 'react';
import '../style/Content.css';
import contentBg from '../../asset/ContentBg.png'; // Import the background image
import content1 from '../../asset/Content1.png'; // Import the left-side image

const Content = () => {
    return (
        <div className="content-section" style={{ backgroundImage: `url(${contentBg})` }}>
            <h1 className="content-heading">REAL STRUGGLE: IMPROVING YOUR SKILLS</h1>
            <div className="content-container">
                {/* Left Side Image */}
                <div className="content-image">
                    <img src={content1} alt="Improving Your Skills" />
                </div>
                
                {/* Right Side Content */}
                <div className="content-points">
                    <ul>
                        <li>The Real Struggle:</li>
                        <li><strong>Understanding the Gaps:</strong> The first step is identifying the gaps in your current skill set and areas that need improvement.</li>
                        <li><strong>Continuous Learning:</strong> Stay committed to learning new skills, whether through formal education or self-paced resources.</li>
                        <li><strong>Practical Application:</strong> Apply what you learn in real-world situations to reinforce and test your skills.</li>
                        <li><strong>Seeking Feedback:</strong> Regularly ask for feedback from peers, mentors, or professionals to understand where you can improve.</li>
                        <li><strong>Persistence and Consistency:</strong> Improvement is a long-term process that requires persistence, consistency, and dedication.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Content;
