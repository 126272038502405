import React from 'react';
import '../style/Home.css';
import logo from '../../asset/logo.png';
import sign from '../../asset/sign.png';
import rightImage from '../../asset/rightImage.png';
import leftImage from '../../asset/leftImage.jpg';
import video1 from '../../asset/video1.mp4';

const Home = () => {
  return (
    <div className="home-container">
      <div className="content">
        <div className='logo'>
            <img src={logo} alt='logo' className='logo-image'/>
        </div>
        <div className='searchbox'>
            <p>Unlock the Quickest Path to Success in 2024 and Beyond with IMTS Institute!</p>
        </div>
        <div className='quote'>
            <p>
                <span className='quote1'>Elevate Your Learning Experience </span>
                <span className='quote2'>with IMTS Where Your </span>
                <span className='quote3'>Future </span>
                <span className='quote4'>Takes Flight.</span>
            <div className='signPosition'>
                <img src={sign} alt='sign' className='sign'/>
            </div>
            </p>
        </div>
        <div className='rightImageBox' >
             <img src={rightImage} alt='left' className='rightImg'/>
        </div>
        <div className='leftImageBox' >
             <img src={leftImage} alt='left' className='leftImg'/>
        </div>
        <div className='writing1'>
            <p>❌ <span className='no'> No </span> Fake Cerificate | ✅<span className='yes'> Online Classes | </span> ⚠️ <span className='no'> No </span> Scam | ✅ <span className='yes'> Placement</span></p>
        </div>
        <div className='writing2'>
            <p>✅ Ready-to-grow | ✅ Ready-to-Shine</p>
        </div>
        <div className="video1">
                <video controls className="videoo">
                    <source src={video1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
        </div>
      </div>
    </div>
  );
}

export default Home;
