import React from 'react';
import '../style/Footer.css';
import { FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-about">
                    <h3>About IMTS</h3>
                    <p>
                        IMTS Institute is a leading educational institution offering a variety of courses in multiple disciplines. We focus on providing quality education, flexible learning options, and career guidance to help students achieve their goals.
                    </p>
                </div>

                <div className="footer-links">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#course">Courses</a></li>
                        <li><a href="#admission">Admission Process</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>

                <div className="footer-contact">
                    <h3>Contact Us</h3>
                    <p>Email: service.imtsinstitute@gmail.com</p>
                    <p>Phone: +91-9210989898</p>
                    <p>Address: G-38, 2nd Floor, Block G, Noida Sector 3, Noida, Uttar Pradesh 201301</p>
                    
                    <div className="social-icons">
                        <a href="https://in.linkedin.com/school/imts-institute/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin className="social-icon linkedin" />
                        </a>
                        <a href="https://www.youtube.com/channel/UC1gyzoIz5byFm4jPkwsFY5A/videos" target="_blank" rel="noopener noreferrer">
                            <FaYoutube className="social-icon youtube" />
                        </a>
                        <a href="https://www.instagram.com/imts_institute/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram className="social-icon instagram" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>© 2024 IMTS Institute. All Rights Reserved.</p>
                <p><a href="#privacy-policy">Privacy Policy</a> | <a href="#terms">Terms & Conditions</a></p>
            </div>
        </footer>
    );
};

export default Footer;
