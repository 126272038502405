import React from 'react';
import '../style/GuideVideo.css';

const GuideVideo = () => {
    return (
        <div className="guide-video-section">
            <h1>Media Coverage</h1>
            <p>Catch our Director's insightful interview on CNBC Awaaz, discussing the latest industry trends and innovations.</p>
            
            {/* Video Section */}
            <div className="video-container">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Y4cTt5rI-Wc?si=6tW9DphyERoAaZCI"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className="guide-video"
                ></iframe>
            </div>
        </div>
    );
};

export default GuideVideo;
