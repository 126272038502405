import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Importing all images from the '../../asset/' directory
import Sl1 from '../../asset/Sl1.png';
import Sl2 from '../../asset/Sl2.png';
import Sl3 from '../../asset/Sl3.png';
import Sl4 from '../../asset/Sl4.png';
import Sl5 from '../../asset/Sl5.png';
import Sl6 from '../../asset/Sl6.png';
import Sl7 from '../../asset/Sl7.png';
import Sl10 from '../../asset/Sl10.png';
import Sl11 from '../../asset/Sl11.png';
import Sl12 from '../../asset/Sl12.png';
import Sl13 from '../../asset/Sl13.png';
import Sl20 from '../../asset/Sl20.jpg';
import Sl21 from '../../asset/Sl21.png';
import Sl22 from '../../asset/Sl22.png';
import Sl23 from '../../asset/Sl23.png';
import Sl24 from '../../asset/Sl24.png';
import Sl25 from '../../asset/Sl25.png';
import Sl26 from '../../asset/Sl26.png';
import Sl27 from '../../asset/Sl27.png';
import Sl28 from '../../asset/Sl28.png';
import Sl29 from '../../asset/Sl29.png';
import Sl30 from '../../asset/Sl30.png';
import Sl31 from '../../asset/Sl31.png';

const PhotoSlider = () => {
  // Slider settings, responsive breakpoints added
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default 4 slides visible at once on desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // 2 seconds per slide
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024, // Tablet and small desktop
        settings: {
          slidesToShow: 3, // Show 3 slides on tablets
        }
      },
      {
        breakpoint: 768, // Medium devices (like large phones)
        settings: {
          slidesToShow: 2, // Show 2 slides on medium screens
        }
      },
      {
        breakpoint: 480, // Small devices (phones)
        settings: {
          slidesToShow: 1, // Show 1 slide on small screens
        }
      }
    ]
  };

  // Array of images and their corresponding names
  const images = [
    { src: Sl1, name: "Photo 1" },
    { src: Sl2, name: "Photo 2" },
    { src: Sl3, name: "Photo 3" },
    { src: Sl4, name: "Photo 4" },
    { src: Sl5, name: "Photo 5" },
    { src: Sl6, name: "Photo 6" },
    { src: Sl7, name: "Photo 7" },
    { src: Sl10, name: "Photo 10" },
    { src: Sl11, name: "Photo 11" },
    { src: Sl12, name: "Photo 12" },
    { src: Sl13, name: "Photo 13" },
    { src: Sl20, name: "Photo 20" },
    { src: Sl21, name: "Photo 21" },
    { src: Sl22, name: "Photo 22" },
    { src: Sl23, name: "Photo 23" },
    { src: Sl24, name: "Photo 24" },
    { src: Sl25, name: "Photo 25" },
    { src: Sl26, name: "Photo 26" },
    { src: Sl27, name: "Photo 27" },
    { src: Sl28, name: "Photo 28" },
    { src: Sl29, name: "Photo 29" },
    { src: Sl30, name: "Photo 30" },
    { src: Sl31, name: "Photo 31" }
  ];

  return (
    <div style={{
      width: '100%',
      maxWidth: '100%',  // Ensure the container is always 100% width
      margin: '0 auto', 
      textAlign: 'center', 
      backgroundColor: 'black',
      overflow: 'hidden'  // Prevent overflow issues
    }}>
      {/* Heading */}
      <br />
      <h2 style={{
        margin: '20px auto 30px auto',
        backgroundColor: '#ffda56',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        borderRadius: '35px',
        padding: '25px',
        color: '#000000',
        fontSize: '32px',
        textTransform: 'uppercase',
        letterSpacing: '1px'
      }}>
        Our Media Coverage
      </h2>

      {/* Image Slider */}
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} style={{
            padding: '1rem', 
            boxSizing: 'border-box', 
            width: '100%',
            display: 'flex',
            flexDirection: 'column',  // Stack image and name vertically
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'  // Needed for positioning name
          }}>
            {/* Image */}
            <div style={{
              position: 'relative',
              width: '100%',
              padding: '0 1rem', // Adds padding to both left and right for spacing
            }}>
              <img
                src={image.src}
                alt={`Slide ${index + 1}`}
                style={{
                  maxWidth: '100%',  // Ensures image doesn't exceed the container width
                  height: '35vh',
                  display: 'block',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                }}
              />
            </div>

            {/* Photo name below the image */}
            <p style={{
              marginTop: '10px',  // Space between image and name
              marginLeft: '17%',  // Shift the name to the right
              color: '#fff',
              fontSize: '18px',
              fontWeight: 'bold',
              textAlign: 'left',  // Left-aligned text
              width: '100%',
              padding: '10px'
            }}>
              {image.name}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PhotoSlider;
