import React from 'react';
import '../style/AdmissionSteps.css';
import courseBg from '../../asset/CourseBg.png'; // Background Image

const Content = () => {
    return (
        <div className="admission-section" style={{ backgroundImage: `url(${courseBg})` }}>
            <h1 className="admission-heading">Steps to Take Admission in IMTS</h1>
            <div className="admission-steps">
                <div className="admission-step">
                    <h2>Step 1</h2>
                    <h3>Open IMTS Institute Website</h3>
                    <p>Visit the official website of IMTS Institute to begin your journey toward a brighter future.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 2</h2>
                    <h3>Fill Admission Form</h3>
                    <p>Complete the online admission form by providing the necessary details about yourself and your course of interest.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 3</h2>
                    <h3>Receive WhatsApp Message</h3>
                    <p>After submitting the form, you will receive a confirmation message on WhatsApp with further instructions.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 4</h2>
                    <h3>Counsellor Calls You</h3>
                    <p>An experienced counsellor will contact you to guide you through the course selection process in best suitable university.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 5</h2>
                    <h3>Discuss Courses</h3>
                    <p>You will discuss your interests, career goals, and the best courses suited to you.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 6</h2>
                    <h3>Clearing the doubt session</h3>
                    <p>Join our doubt-clearing session to learn about university, courses, fees and the complete admission process overview.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 7</h2>
                    <h3>Make Payment</h3>
                    <p>Once you have chosen your course, proceed with the payment to secure your admission.</p>
                </div>

                <div className="admission-step">
                    <h2>Step 8</h2>
                    <h3>Welcome to IMTS Institute</h3>
                    <p>Congratulations! You are now officially a part of IMTS Institute. Get ready to embark on your learning journey!</p>
                </div>
            </div>
        </div>
    );
};

export default Content;
